





































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Watch } from 'vue-property-decorator';
import Vue from 'vue';
import { mixins } from 'vue-class-component';
import { getConfigEnv, getComponent, getView, sleep } from '@/utils/helpers';
import Multiselect from 'vue-multiselect';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import tasqsListModule from '@/store/modules/tasqsListModule';
import DataLoading from '@/lib/mixins/dataLoading';
import TasqMixin from '@/lib/mixins/TasqMixin';
import tasqSignalsModule from '@/store/modules/tasqSignalsModule';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
// import { ALL_TASQS_LIST_ITEM, SHOW_ALERT} from '@/lib/constants';
import accountModule from '@/store/modules/accountModule';
import assetsModule from '@/store/modules/assetsModule';
import tasqActionsModule from '@/store/modules/tasqActionsModule';
import defermentLabelingModule from '@/store/modules/defermentLabelingModule';
import setpointV2Module from '@/store/modules/setpointV2Module';
import workflowModule from '@/store/modules/workflowModule';
import tasqFeedbackModule from '@/store/modules/tasqFeedbackModule';
import DateRangePicker from 'vue2-daterange-picker';
// you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import VueTagsInput from '@johmun/vue-tags-input';
import workspaceModule from '@/store/modules/workspaceModule';
import TasqLightningProductionChart from '../components/tasqs/TasqLightningProductionChart.vue';

import TasqLightningChart from '../components/tasqs/TasqLightningChart.vue';

import { getNameByEmail } from '@/utils/users';
import { datadogRum } from '@datadog/browser-rum';
import {
  preloadData,
} from '@/utils/preloadOfflineData';
import {
  ALL_TASQS_LIST_ITEM,
  SHOW_ALERT,
  TASQ_OFF_TARGET_TYPE,
  TASQ_WAITING_ON_LIST,
  WELL_CLICK_EVENT,
} from '@/lib/constants';
import scheduleModule from '@/store/modules/scheduleModule';
import { Debounce } from 'vue-debounce-decorator';
import metaDataModule from '@/store/modules/metaDataModule';
@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    TasqsVerticalList: () => getComponent('tasqs/TasqsVerticalList'),
    TasqSignal: () => getComponent('tasqs/TasqSignal'),
    TasqProductionDataChart: () => getComponent('tasqs/TasqProductionDataChart'),
    TasqFeedback: () => getComponent('tasqs/TasqFeedbackV4'),
    TasqLoader: () => getComponent('loaders/TasqLoader'),
    TasqItem: () => getComponent('tasqs/TasqItem'),
    ChartIq: () => getView('ChartIQ'),
	TasqLightningChart: () => getComponent('tasqs/TasqLightningChart'),
	TasqLightningProductionChart: () => getComponent('tasqs/TasqLightningProductionChart'),
    manualTasq: () => getComponent('tasqs/TasqManualPopup'),
    eventDetail: () => getComponent('tasqs/TasqEventSummaryPopup'),
    Multiselect,
    DateRangePicker,
    VueTagsInput,
  },
})
export default class
TasqsDesktop extends mixins(DataLoading, TasqMixin) {
  chartRefreshKey = 0
  legendRefreshKey = 0
  legendRefreshKeyProd = 0
  showFullScreenSignalsChart = false
  showFullScreenProductionChart = false
  isLoadingSignals = true
  isLoadingProductionData = true
  tasqOptionsOpen = false;
  chartsLoading = false;
  showAddManualTasq = false;
  tag: any = '';
  tags: any = [];
  activeTableID: any = '';
  showLinkSharePopup = false;
  activeWorkspaceID: any = '';
  showChartModal: any = false;
  autoCompleteTags: any = [];
  addTasqAssetType = ''
  equipment: any = '';
  equipments: any = [];
  signalChartTime = 60;
  loadingTasqs = false;
  isOnlineApp = true;
  customSignalRange: any = { startDate: null, endDate: null }
  showComment = false;
  multiTasqType = '';
  subPredictionList: any = [];
  comment = '';
  visiblePadWellsFrom = 0
  visiblePadWellsTo = 5
  showActionDialog: boolean = false;
  showDataLoadingFeedback = false
  actionErrors: any = [];
  chartTimes: any[] = this.$getConst('CHART_TIMES');
  testSlowItDown = false
  showWellEventsLocal = false;
  failedToPullProductionData = false


  //   windowWidth = window.innerWidth;
  //   get isNarrowScreen() {
  //      return this.windowWidth < 1550;
  //    }
  get showWellEvents() {
    return tasqProductionDataChartModule.showWellEvents;
  }
  get isEditing() {
	  return tasqsListModule.isEditing;
  }


  didSelectResetChart(type) {
	if (type == "tasq-lightning-chart") {
		(this.$refs.myChildRef as TasqLightningChart).didSelectResetChart()
	} else if (type == "tasq-lightning-chart-full") {
		(this.$refs.myChildRefFull as TasqLightningChart).didSelectResetChart()
	} else if (type == "tasq-lightning-chart-prod") {
		(this.$refs.myChildRefProd as TasqLightningProductionChart).didSelectResetChart()
	} else if (type == "tasq-lightning-chart-prod-full") {
		(this.$refs.myChildRefProdFull as TasqLightningProductionChart).didSelectResetChart()
	}
	
	
  }

  isSignalSelected(signal_name) {
	return (tasqSignalsModule.selectedSignals.indexOf(signal_name) > -1)
  }

  isSignalSelectedProd(signal_name) {
	return (tasqProductionDataChartModule.selectedSignals.indexOf(signal_name) > -1)
  }


	getCurrentSignalNameForLegend(signal_name) {
		if (signal_name == "gas_rate") {
			return "Gas Rate"
		} else if (signal_name == "oil_rate") {
			return "Oil Rate"
		} else if (signal_name == "water_rate") {
			return "Water Rate"
		}
		return signal_name
	}

  updateChartToMatchLegend() {
	if (this.showFullScreenSignalsChart) {
	} else {
		(this.$refs.myChildRef as TasqLightningChart).updateChartToMatchLegend()
		console.log("Doing a full reset for main page hopefully")
	}
	

  }

  didSelectSignalOnLegendProd(index, signal) {
	if (!this.showFullScreenProductionChart) {
		(this.$refs.myChildRefProd as TasqLightningProductionChart).initializePage(index, signal)
		tasqProductionDataChartModule.selectSignal(signal)
		this.legendRefreshKeyProd += 1
	} else {
		(this.$refs.myChildRefProdFull as TasqLightningProductionChart).initializePage(index, signal)
		tasqProductionDataChartModule.selectSignal(signal)
		this.legendRefreshKeyProd += 1	
	}
  }

  didSelectSignalOnLegend(index, signal) {
	if (!this.showFullScreenSignalsChart) {
		(this.$refs.myChildRef as TasqLightningChart).initializePage(index, signal)
		tasqSignalsModule.selectSignal(signal)
		this.legendRefreshKey += 1
	} else {
		(this.$refs.myChildRefFull as TasqLightningChart).initializePage(index, signal)
		tasqSignalsModule.selectSignal(signal)
		this.legendRefreshKey += 1	
	}
  }

  didSelectProductionFullScreen() {
	this.showFullScreenProductionChart = !this.showFullScreenProductionChart
  }
  didSelectSignalsFullScreen() {
	this.showFullScreenSignalsChart = !this.showFullScreenSignalsChart
	this.updateChartToMatchLegend()
  }
  addTasqDidSelectAssetTasq() {
  }
  closeEditToolbar() {
	tasqsListModule.setIsEditing(false);
	tasqsListModule.setIsBatchResponding(false);
	tasqsListModule.setIsBatchReassign(false);
	// tasqsListModule.setActiveTasq('');
	tasqsListModule.resetChekedTasqs();
  }
  openAddTag() {
    // @ts-ignore
    document.querySelector('.customUINew input').style.background = 'transparent';
    // @ts-ignore
    document.querySelector('.customUINew input').style.maxWidth = '100px';
    const element: any = document.querySelector('.customUINew');
    const element2 = element.querySelector('input');
    if (element) {
      const dropdown = element.querySelector('.multiselect__content-wrapper');
      const cor = element.getBoundingClientRect();
      const top = cor.top - 300;
      // console.log(cor)
      dropdown.style.left = `${cor.x}px`;
    }
  }
  closeAddTag() {
    // @ts-ignore
    document.querySelector('.customUINew input').style.background = '#495364';
    // @ts-ignore
    document.querySelector('.customUINew input').style.display = 'block';
  }
  async toggleChartModal() {
    this.showChartModal = !this.showChartModal;
	this.failedToPullProductionData = false
    this.chartsLoading = true;
	this.isLoadingSignals = true
    const elements = document.querySelectorAll('.tasqs-grid')![0];
    if (this.showChartModal) {
      //  @ts-ignore
      elements.style.gridTemplateColumns = '310px auto 0px';
    } else {
      const promises: any = [];
      promises.push(tasqSignalsModule.getSignalsForTasq(this.signalChartTime));
      this.chartsLoading = false
      Promise.all(promises).then(async () => {
		this.chartRefreshKey += 1
		this.isLoadingSignals = false
        // await setpointModule.getSetpointV3DataForTasq({ wellName: this.activeTasq?.wellName });
      }, (err) => {
        console.log('Error:');
        console.log(err);
      });
	  this.isLoadingProductionData = true
      const production_promises: any = [];
      production_promises.push(tasqProductionDataChartModule.getProductionData(14));
      Promise.all(production_promises).then(async () => {
		tasqsListModule.setIsInitialPageLoad(false)
		this.isLoadingProductionData = false
		this.chartRefreshKey += 1
        // await setpointModule.getSetpointV3DataForTasq({ wellName: this.activeTasq?.wellName });
      }, (err) => {
        console.log('Error:');
        console.log(err);
		this.failedToPullProductionData = true
      });
      //  @ts-ignore
      elements.style.gridTemplateColumns = '310px auto 400px';
    }
    this.sleep(1000).then(() => {
      this.chartsLoading = false;
    });
  }
  closeShareableDialog() {
  this.showLinkSharePopup = false;
  }
  async chartModalSetup() {
    this.showChartModal = false;
    const elements = document.querySelectorAll('.tasqs-grid')![0];
    if (elements) {
      //  @ts-ignore
      elements.style.gridTemplateColumns = '310px auto 400px';
    }
  }


  get productionData() {
	return tasqProductionDataChartModule.productionData
  }

  get selectedCustomRange() {
    // console.log(this.customSignalRange);
    if (this.customSignalRange.startDate && this.customSignalRange.endDate) {
      return true;
    }
    return false;
  }
  get dateRange() {
    const startDate = new Date();
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 6);
    return {
      dateRange: { startDate, endDate },
    };
  }
  get isBatchResponding() {
	  return tasqsListModule.isBatchResponding;
  }
  async batchRespond() {
	  if (this.respondEnabled) {
    //   workflowModule.getJobResponse(tasqsListModule.checkedTasqs[0]);
	// 	  await workflowModule.getJobResponseV2(tasqsListModule.checkedTasqs[0]);
	// 	  this.showDataLoadingFeedback = false;
	// 	  // if (workflowModule.v2ResponseData != null) {
    //   //   proceduresModule.getProceduresStepsJson({
    //   // 	  jsonVersion: workflowModule.v2ResponseData.jsonVersion,
    //   //   });
	// 	  // } else {
    //   //   proceduresModule.getProceduresStepsJson({});
	// 	  // }
	// 	  tasqsListModule.setIsBatchResponding(true);
	  }
  }
  async batchReassign() {
	  if (this.respondEnabled) {
      tasqsListModule.setIsBatchReassign(true);
      accountModule.getReassignmentList({ useExisting: true });
		  this.prepareAction('reassign');
	  }
  }
  get adminUser() {
    return workflowModule.user.adminChartAccess;
  }
  async batchWait() {
	  if (this.respondEnabled) {
      tasqsListModule.setIsBatchReassign(true);
		  this.prepareAction('wait');
	  }
  }
  get getBatchEditResponseDisabledMsg() {
	  return tasqsListModule.batchResponseDisabledMsg;
  }
  get respondEnabled() {
	  return tasqsListModule.batchResponseEnabled;
  }
  get selectedEditItemsCount() {
	  return tasqsListModule.checkedTasqs.length;
  }
  padWellsSkipToEnd() {
	  this.visiblePadWellsFrom = (Math.ceil(this.activeTasq?.wells.length / 6) - 1) * 6;
	  this.visiblePadWellsTo = this.visiblePadWellsFrom + 5;
  }
  padWellsNext() {
	  if (this.visiblePadWellsTo >= this.activeTasq?.wells.length || this.visiblePadWellsFrom + 6 >= this.activeTasq?.wells.length) {
		  return;
	  }
	  this.visiblePadWellsFrom += 6;
	  this.visiblePadWellsTo += 6;
  }
  padWellsPrevious() {
	  if (this.visiblePadWellsFrom - 6 < 0) {
		  return;
	  }
	  this.visiblePadWellsFrom -= 6;
	  this.visiblePadWellsTo -= 6;
  }
  padWellsSkipToBeginning() {
    this.visiblePadWellsFrom = 0;
    this.visiblePadWellsTo = 5;
  }
  get didLoadAllSignals() {
	return tasqSignalsModule.didLoadAllSignals
  }
  get dateRangeCustomPreset() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return {
      'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()), new Date(today.getFullYear(), today.getMonth(), today.getDate())],
      'Last 3 months': [new Date(today.getFullYear(), today.getMonth() - 3, today.getDate()), new Date(today.getFullYear(), today.getMonth(), today.getDate())],
      'Last 6 months': [new Date(today.getFullYear(), today.getMonth() - 6, today.getDate()), new Date(today.getFullYear(), today.getMonth(), today.getDate())],
      'Last Year': [new Date(today.getFullYear(), today.getMonth() - 12, today.getDate()), new Date(today.getFullYear(), today.getMonth(), today.getDate())],
    };
  }
  get showErrorMsgTooltip() {
	  return !this.respondEnabled && tasqsListModule.checkedTasqs.length > 0;
  }
  get tasqs(): TasqJob[] {
    return tasqsListModule.allTasqs;
  }

  get activeTasqs(): TasqJob[] {
    return tasqsListModule.tasqList;
  }

  get wellTasqs(): TasqJob[] {
    return tasqsListModule.additionalPadNestedTasqs;
  }
  get producingWells(): TasqJob[] {
    return tasqsListModule.producingWells;
  }
  get getWells() {
    let responseData: any[] = [];
	  if (this.activeTasq != null) {
		  // @ts-ignore
      if (this.routeQueryView === 'wellview' && this.tasqListLevel.toLowerCase() === 'well') {
        console.log('dkfjajsdfjklasdfkl');
        const newResponseData: any = this.activeTasqs.filter((well) => well.wellName === this.activeTasq?.wellName);
	  if (responseData) {
          responseData = newResponseData.concat(responseData);
	  }
      } else if (this.tasqListLevel.toLowerCase() === 'pad') {
        for (let r = 0; r < this.activeTasq.wells.length; r++) {
			  // @ts-ignore
          //   if (this.activeTasq.wells[r].predictionType != "Producing") {
          if (this.activeTasq.wells[r].id) {
				  // @ts-ignore
				  responseData.push(this.activeTasq.wells[r]);
          }
          //   }
		  }
      } else if (this.tasqListLevel.toLowerCase() === 'wellview') {
        // for (let x = 0; x < this.producingWells.length; x++) {
        //   if (this.activeTasq.wellName != null && this.producingWells[x].wellName == this.activeTasq.wellName) {
        //     responseData.push(this.producingWells[x]);
        //   }
        // }
	  const newResponseData: any = this.activeTasqs.filter((well) => well.wellName === this.activeTasq?.wellName);
	  if (responseData) {
          responseData = newResponseData.concat(responseData);
	  }
      }
	  }
    // console.log(responseData)
	  return responseData;
    //   if (this.showAllWells) {
    // 	  // @ts-ignore
    // 	  return this.tasq.wells
    //   } else {
    //   }
  }
  get tasqListLevel() {
	  if (tasqsListModule.tasqListLevel.toLowerCase() == 'well' || (this.activeTasq && tasqsListModule.tasqListLevel.toLowerCase() !== 'wellview' && this.activeTasq.level.toLowerCase() == 'well')) {
		  return 'Well';
	  }
	  return tasqsListModule.tasqListLevel.toLowerCase();
    //   if (this.activeTasq == null) {
    // 	  return 'WELL';
    //   }
    //   return this.activeTasq?.level;
  }
  get isProductionDataLoaded() {
    return tasqProductionDataChartModule.productionData.loaded;
  }
  get currentWellType(): any {
	  return this.$route.query.type;
  }
  get signalDescriptions(): any {
	  return tasqSignalsModule.signalDescriptions;
  }
  get currentSignals(): any {
	return tasqSignalsModule.currentSignals
  }

  get productionDataDict(): any {
	return tasqProductionDataChartModule.productionDataDict
  }



  get legendSignalsProd(): any {
	var legend_signals:any = []

	for (const [key, value] of Object.entries(this.productionDataDict)) {
		if (key == "date" || value == null || value == undefined || key == "nodeid") {
			continue
		}


		if ("water_rate" != key && "gas_rate" != key && "oil_rate" != key) {
			continue
		}
		var color = ''
		if ("water_rate" == key) {
			color = "#0077ff"
		} else if ("gas_rate" == key) {
			color = "#f55d8b"
		} else if ("oil_rate" == key) {
			color = "#2de6c1"
		}
		legend_signals.push({
			name: key,
			selected: this.isSignalSelected(key),
			color: color,
			type: "PRODUCTION"
		})
	}
	return legend_signals
  }


  get legendSignals(): any {
	var legend_signals:any = []
	for (var x = 0; x < this.currentSignals.length; x++) {
		legend_signals.push({
			name: this.currentSignals[x].name,
			selected: this.isSignalSelected(this.currentSignals[x].name),
			color: this.currentSignals[x].color,
			type: "SIGNAL"
		})
	}

	for (const [key, value] of Object.entries(this.productionDataDict)) {
		
		// @ts-ignore
		if (("water_rate" != key && "gas_rate" != key && "oil_rate" != key) || (value == undefined || value.length == 0)) {
			continue
		}
		var color = ''
		if ("water_rate" == key) {
			color = "#0077ff"
		} else if ("gas_rate" == key) {
			color = "#f55d8b"
		} else if ("oil_rate" == key) {
			color = "#2de6c1"
		}
		legend_signals.push({
			name: key,
			selected: this.isSignalSelected(key),
			color: color,
			type: "PRODUCTION"
		})
	}
	return legend_signals
  }



//   get currentSignals(): any {
//     if (this.isLoadingSignals) {
//       return [];
//     }
//     const results: any[] = [];
//     for (let x = 0; x < this.signalDescriptions.length; x++) {
//       // @ts-ignore
//       if (this.tasqListLevel.toLowerCase() == 'pad') {
//         if (!(this.signalDescriptions[x].indexOf('Compressor') !== -1)) {
//           continue;
//         }
//       }
//       for (let y = 0; y < tasqSignalsModule.currentSignals.length; y++) {
//         if (tasqSignalsModule.currentSignals[y].name == this.signalDescriptions[x]) {
//           results.push(tasqSignalsModule.currentSignals[y]);
//         }
//       }
//     }
//     // signalDescriptions
//     return results;
//   }
  async tagsAdded(newTag) {
    this.tags.push(newTag.toLowerCase());
    //  newTags.push(newTag)
    await scheduleModule.addTagsToWell({ node_id: this.activeTasq?.wellName, tags: this.tags });
  }
  async tagsChanged(newTag) {
  //  let newTags = this.tags;
    //  newTags.push(newTag)
    await scheduleModule.addTagsToWell({ node_id: this.activeTasq?.wellName, tags: [newTag.toLowerCase()].concat(this.tags) });
  }
  async tagsRemove(removeT) {
    const index = this.tags.indexOf(removeT);
    if (index > -1) {
      this.tags.splice(index, 1);
    }
    await scheduleModule.addTagsToWell({ node_id: this.activeTasq?.wellName, tags: this.tags });
  }
  get routeQueryType() {
    return this.$route.query.type;
  }
  get routeQueryView() {
    return this.$route.query.view;
  }
  getPredictionList() {
    this.subPredictionList = [];
    if (this.activeTasq && this.activeTasq.predictionsList && Object.keys(this.activeTasq.predictionsList) && this.routeQueryType !== 'producing') {
      this.activePredictionItem = this.activeTasq.id;
      let found = false;
      this.subPredictionList = Object.keys(this.activeTasq.predictionsList).map((data) => {
        const object:any = this.activeTasq?.predictionsList[data];
        if (data === this.activeTasq?.overriddenName) {
          found = true;
        }
        if (data === 'Off-Target RT') {
          data = 'REAL-Time';
        }
        return { name: data, predictionId: object.PredictionID, color: this.bgColorClass(data) };
      });
      if (!found) {
        this.subPredictionList.push({ name: this.activeTasq.engineerType, predictionId: this.activeTasq.id, color: this.bgColorClass(this.activeTasq.overriddenName) });
      }
    } else {
      this.subPredictionList = [];
    }
  }
  get activeTasq() {
    if (this.isEditing || tasqsListModule.checkedTasqs.length) {
		  if (!tasqsListModule.activeTasq) {
        tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
      }
		  return tasqsListModule.activeTasq as TasqJob;
	  }
    if (this.$route.query.type == 'id' && this.$route.params.id != null) {
      assetsModule.setActiveProducingTasq('');
      return tasqsListModule.activeTasq;
    }
    if (tasqsListModule.activeTasq != null && this.$route.params.id != null && this.$route.query.type == 'producing' && tasqsListModule.activeTasq.level.toLowerCase() == 'pad') {
	  return tasqsListModule.activeTasq;
    }
    if (assetsModule.activeTasq == undefined) {
      return tasqsListModule.activeTasq;
    }
    return assetsModule.activeTasq;
  }
  get reassignUsers() {
    return accountModule.reassignmentList.map((i) => ({
      // @ts-ignore
      text: i.name,
      value: i.email,
    })).sort((a, b) => a.text.localeCompare(b.text));
  }
  get enabledPads() {
    return assetsModule.enabledPads;
  }
  get enabledWells() {
    return assetsModule.enabledWells;
  }
  get enabledWellsMapping() {
    return assetsModule.enabledWells.map((well) => ({ text: well.text, value: well.value }));
  }
  resetTasq() {
    tasqFeedbackModule.resetAllData();
  }
  @Debounce(1000)
  showEventCommentPopup(comment) {
    if (comment && comment.Username) {
      let actions: any = '';
      if (comment.ResponseData && comment.ResponseData && comment.ResponseData.Actions) {
        actions = this.getCommentActions(comment);
      }
      this.wellEventDetails = {
        action: comment.ActionsInputJSON,
        createdBy: getNameByEmail(comment.Username),
        createdDate: comment.time,
        comment: comment.readableComment,
        jobType: comment.JobType,
      };
      this.showWellEventDetail = true;
    }
  }
  get waitingOnOptions() {
    return metaDataModule.waitingOnList;
  }
  async created() {
	tasqsListModule.setIsInitialPageLoad(true)
	// await sleep(1000)
	this.testSlowItDown = true
	this.isLoadingSignals = true
    await metaDataModule.getActionsBuilder();
    tasqsListModule.setActivePage('Tasq');
    this.$eventBus.$on(WELL_CLICK_EVENT, (comment) => {
      this.showEventCommentPopup(comment);
    });
    this.isOnlineApp = navigator.onLine;
    tasqsListModule.setFromNowBack(0);
    this.sleep(5000).then(() => {
      const promiseList: any = [];
      // promiseList.push(assetsModule.getEnabledPads());
      promiseList.push(accountModule.getReassignmentList({ useExisting: true }));
      promiseList.push(assetsModule.getEnabledWells());
      Promise.all(promiseList).then(async () => {
        // console.log('resolved');
        if (this.isOnlineApp) {
          workspaceModule.getWorkspaces(null);
          if (!accountModule.didPreloadData) {
            // await preloadData();
          }
			datadogRum.init({
				applicationId: '42a5cbbe-cee7-4b16-b31c-27f8330f507b',
				clientToken: 'pubb848c6a76f8811fde5cd24dc0264f13f',
				site: 'datadoghq.com',
				service:'tasq',
				env:getConfigEnv("BRANCH_NAME"),
				// Specify a version number to identify the deployed version of your application in Datadog
				version: accountModule.user.email,
				sampleRate: 100,
				premiumSampleRate: 100,
				trackInteractions: true,
				defaultPrivacyLevel:'allow'
			});
			datadogRum.startSessionReplayRecording();
        }
        await tasqsListModule.getTasqListResponses();
      }, (err) => {
        console.log('Error:');
        console.log(err);
      });
    });
    this.loadingTasqs = true;
  }
  
  async fetchTasqAsync() {
    if (this.$route.params.id != null && this.$route.params.id != '' && this.$route.query.type == 'id') {
      await this.fetchTasq();
    }
  }
  beforeDestroy() {
    tasqsListModule.setActiveTasq('');
  }
  editTasqs() {
	  if (tasqsListModule.isEditing) {
		  this.closeEditToolbar();
	  } else {
		  tasqsListModule.setActiveTasq('');
		  tasqsListModule.setIsEditing(true);
	  }
  }
  activePredictionItem: any = ''
  async updateTasqPrediction(data) {
    assetsModule.setActiveProducingTasq('');
    window.history.pushState('tasqs', 'Title', `/tasqs/${data.predictionId}?type=id`);
    this.multiTasqType = data.name;
    this.showDataLoadingFeedback = true;
    workflowModule.setV2ResponseData(null);
    tasqFeedbackModule.resetAllData();
    this.activePredictionItem = data.predictionId;
    await this.fetchTasq(data.predictionId, true);
    this.showDataLoadingFeedback = false;
  }
  editWaitingOnStatus() {
    if (this.activeTasq && this.activeTasq.waitingOnStartDate && this.activeTasq.waitingOnEndDate) {
    // console.log(this.activeTasq.snoozed.WaitStartDate);
      this.action.type = 'wait';
      this.action.startDate = this.activeTasq?.snoozed.WaitStartDate;
      this.action.date = this.activeTasq?.snoozed.UntilDate;
      if (this.activeTasq.waitUntilReason) {
        this.action.reason = this.activeTasq?.waitUntilReason.split('-Remarks:')[0];
      }
      // this.action.comment = this.activeTasq.waitUntilReason.split('-')[1];
      this.action.title = `Please select status for ${this.activeTasq?.wellName}:`;
      this.action.confirmText = 'Update';
      this.$dialog.show('actionModal');
    }
  }
  prepareAction(action) {
    this.showActionDialog = true;
    this.action = {
      type: '',
      title: '',
      confirmText: '',
      reason: '',
      person: '',
      comment: '',
      date: '',
      well: '',
      startDate: new Date(),
      jobType: '',
      frequency: 'Today',
      delayedDays: 0,
    };
    switch (action) {
      case ('reject'):
        this.action.type = action;
        this.action.title = `Are you sure you want to reject ${this.activeTasq?.wellName}?`;
        this.action.confirmText = 'Yes, reject it';
        this.$dialog.show('actionModal');
        break;
      case ('reassign'):
        this.action.type = action;
        this.action.title = `Select the user to reassign ${this.activeTasq?.wellName}:`;
        this.action.confirmText = 'Reassign tasq';
        this.$dialog.show('actionModal');
        break;
      case ('wait'):
        this.action.type = action;
        this.action.title = `Please select status for ${this.activeTasq?.wellName}:`;
        this.action.confirmText = 'Update';
        this.$dialog.show('actionModal');
        break;
      case ('add'):
        this.showAddManualTasq = true;
        break;
      default:
        break;
    }
  }
  openDialog() {
    this.sleep(300).then(() => {
      this.$dialog.show('actionModal');
    });
  }
  validateDialog(type) {
    if (type === 'reassign') {
      if (!this.action.person) {
        this.actionErrors.push('person');
      }
    } else if (type === 'wait') {
      if (!this.action.date) {
        this.actionErrors.push('date');
      }
      if (!this.action.startDate) {
        this.actionErrors.push('startDate');
      }
      if (!this.action.reason) {
        this.actionErrors.push('reason');
      }
      if (this.action.date && this.action.startDate && this.action.date < this.action.startDate) {
        this.actionErrors.push('invalidEndDate');
      }
    } else if (type === 'reject') {
      if (!this.action.reason) {
        this.actionErrors.push('reason');
      }
    } else if (type === 'add') {
      if (!this.addTasqAssetType) {
        this.actionErrors.push('addTasqAssetType');
      } else {
        if (!this.action.jobType) {
          this.actionErrors.push('jobType');
        }
        if (!this.action.person) {
          this.actionErrors.push('person');
        }
        if (!this.action.well) {
          this.actionErrors.push('well');
        }
      }
    }
  }
  async onActionDialogClosed(response) {
    let successText = ''!;
    this.actionErrors = [];
    const tasqID = this.activeTasq?.id;
    // this.startDataLoading();
    if (response.isOk) {
      this.validateDialog(this.action.type);
      if (this.actionErrors.length) {
        this.openDialog();
        return;
      }
      switch (this.action.type) {
        case ('reassign'):
          successText = 'Successfully reassigned!';
          await tasqActionsModule.reassignTasq(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        case ('reject'):
          successText = 'Successfully Rejected!';
          await tasqActionsModule.rejectTasq(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        case ('wait'):
          successText = 'Successfully added to waitlist!';
          await tasqActionsModule.updateTasqStatus(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        case ('add'):
          successText = 'Tasq added successfully!';
		  this.action.assetType = this.addTasqAssetType;
          await tasqActionsModule.addTasq(this.action);
          tasqsListModule.resetSetActiveTasq();
          break;
        default:
          break;
      }
      this.closeEditToolbar();
      this.action = {};
      this.showActionDialog = false;
       this.$router.push({ name: 'Tasqs' });
      await sleep(3000);
       const updatedTasq = await tasqsListModule.getRawTasq({ PredictionID: tasqID });
        console.log(updatedTasq);
    //   this.stopDataLoading();
      if (successText) {
        this.$eventBus.$emit(SHOW_ALERT, successText);
      }
    }
  }
  async snoozeTasqForToday() {
    const tasqID = this.activeTasq?.id;
    this.startDataLoading();
    const action = {
      reason: 'Snoozed',
      comment: '',
      date: new Date().setDate(new Date().getDate() + 1),
      id: this.activeTasq?.id,
      startDate: new Date(),
    };
    const successText = 'Successfully snoozed!';
    this.snoozeTasqPopup = false;
    await tasqActionsModule.updateTasqStatus(action);
    this.$router.push({ name: 'Tasqs' });
    await sleep(2000);
    await tasqsListModule.getRawTasq({ PredictionID: tasqID });
    this.stopDataLoading();
    if (successText) {
      this.$eventBus.$emit(SHOW_ALERT, successText);
    }
  }
  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  async customDateUpdated(data) {
    let fromNowBack = 0;
    const dateNow: any = new Date();
    const date1 = data.startDate;
    const date2 = data.endDate;
    if (Math.ceil(Math.abs(date2)) < Math.ceil(Math.abs(dateNow))) {
      fromNowBack = Math.ceil(Math.abs(date2 - dateNow) / (1000 * 60 * 60 * 24));
    }
    tasqsListModule.setFromNowBack(fromNowBack);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    await this.changeChartTimes(diffDays);
  }
  hidePopup() {
    if (this.showChartModal) {
      this.toggleChartModal();
    }
  }
  async changeChartTimes(val) {
	
	this.isLoadingSignals = true
    this.signalChartTime = val;
    tasqsListModule.setSignalChartTime(val);
    const promises: any[] = [];
    promises.push(tasqSignalsModule.getSignalsForTasq(val));
    Promise.all(promises).then(() => {
    //   this.chartsLoading = false;
	  this.chartRefreshKey += 1
	  this.isLoadingSignals = false
    }, (err) => {
      // error occurred
    //   this.chartsLoading = false;
    });
    // const production_promises: any[] = [];
	// this.isLoadingProductionData = true
    // production_promises.push(tasqProductionDataChartModule.getWellHistory(val));
    // production_promises.push(defermentLabelingModule.getChartLabelingData(this.signalChartTime));
    // Promise.all(production_promises).then(() => {
	//   this.isLoadingProductionData = false
    //   this.chartsLoading = false;
	//   this.chartRefreshKey += 1
	// //   this.isLoadingSignals = false
    // }, (err) => {
    //   // error occurred
    //   this.chartsLoading = false;
    // });
  }
  async fetchTasq(predictionID = null, loadingFromSubPrediction = false) {
    this.chartModalSetup();
    this.wellEventDetails = this.wellEventDetailsDefault;
    this.chartTimes = this.$getConst('CHART_TIMES');
    this.showDataLoadingFeedback = true;
    tasqsListModule.setTasqEventHistoryList([]);
    workflowModule.setWellHistory([]);
    this.signalChartTime = 60;
    if (!loadingFromSubPrediction) {
      this.activePredictionItem = '';
      tasqsListModule.setFromNowBack(0);
      tasqSignalsModule.resetSignals();
      tasqProductionDataChartModule.resetProductionData();
      this.customSignalRange = { startDate: null, endDate: null };
    }
    if (this.activeTasq?.engineerType === TASQ_OFF_TARGET_TYPE) {
      let jobOriginExists = false;
      for (let t = 0; t < this.chartTimes.length; t++) {
        if (this.chartTimes[t].text == 'Job Origin') {
          jobOriginExists = true;
        }
      }
      if (!jobOriginExists) {
        this.chartTimes = [...this.chartTimes];
        this.signalChartTime = 60;
      }
    }
    this.isOnlineApp = navigator.onLine;
    workflowModule.setV2ResponseData(null);
    tasqsListModule.setFromNowBack(0);
    tasqProductionDataChartModule.resetProductionDataDefermentLabeling();
    tasqsListModule.setSignalChartTime(this.signalChartTime);
    if (predictionID != null) {
      // @ts-ignore
      if (!loadingFromSubPrediction) {
        this.chartsLoading = true;
        // @ts-ignore
        tasqsListModule.setActiveTasq(predictionID);
        if (tasqsListModule.activeTasq == null) {
          if (this.tasqListLevel.toLowerCase() !== 'pad') {
            tasqsListModule.getTasq({
              PredictionID: this.$route.params.id,
            });
          }
        }
      }
    } else if (this.$route.params.id != null && this.$route.params.id != '' && this.$route.query.type == 'id') {
      if (!this.isOnlineApp) {
        tasqsListModule.setActiveTasq(this.$route.params.id);
      } else if (this.tasqListLevel.toLowerCase() !== 'pad') {
        tasqsListModule.getTasq({
			  PredictionID: this.$route.params.id,
		  });
      }
    } else {
      assetsModule.setActiveProducingTasq(this.$route.params.id);
    }
      const tasqMainPromises: any = [];
      this.showDataLoadingFeedback = false;
  
    if (!loadingFromSubPrediction) {
       this.chartsLoading = false;

     if(this.activeTasq && this.activeTasq.level !== 'PAD'){
      const promises: any[] = [];
      promises.push(tasqSignalsModule.getSignalsForTasq(this.signalChartTime));
      Promise.all(promises).then(async () => {
		    this.isLoadingSignals = false
        this.$nextTick(() => {
         })
      }, (err) => {
        console.log('Error:');
        console.log(err);
      });
      const production_promises: any[] = [];
	this.isLoadingProductionData = true
	this.failedToPullProductionData = false
      production_promises.push(tasqProductionDataChartModule.getProductionData(365));
      Promise.all(production_promises).then(async () => {
		this.isLoadingProductionData = false
    // let t =  Math.floor(new Date().getTime()) - t;
    // console.log(new Date().getTime());
    // console.error(t);
		    // this.isLoadingSignals = false
			tasqsListModule.setIsInitialPageLoad(false)
         this.chartsLoading = true;
        this.$nextTick(() => {
            this.chartsLoading = false;
         })
        // await defermentLabelingModule.getChartLabelingData(this.signalChartTime);
        // this.getPredictionList();
    
       
      }, (err) => {
        console.log('Error:');
        console.log(err);
      });
    }
    }
     else {
      tasqMainPromises.push(workflowModule.getWellHistory(this.activeTasq?.wellName));
    }
    if (this.activeTasq != null) {
      const activeTasqID = predictionID != null ? predictionID : this.activeTasq?.id;
		  tasqMainPromises.push(workflowModule.getJobResponseV2(activeTasqID));
		  // if (loadingFromSubPrediction) {
			//   this.chartsLoading = false;
		  // }
      Promise.all(tasqMainPromises).then(async () => {
        workflowModule.getWellHistory(this.activeTasq?.wellName);
        if (this.activeTasq != null && (this.currentWellType != 'producing')) {
          tasqsListModule.getTasqEventHistoryList(this.activeTasq?.workflowTasqId);
        }
       
        if (this.isOnlineApp) {
          let wellTags = await scheduleModule.getTagsForWell({ node_id: this.activeTasq?.wellName });
          wellTags = wellTags.map((t) => JSON.parse(t).toLowerCase());
          this.tags = [...new Set(wellTags)].sort();
          let availableTags = await scheduleModule.getTagsForWell({ node_id: null });
          availableTags = availableTags.map((t) => JSON.parse(t).toLowerCase());
          this.autoCompleteTags = [...new Set(availableTags)].sort();
        }
       
      }, (err) => {
        console.log('Error: Failed to pull production data');
        console.log(err);
		this.failedToPullProductionData = true
      });
    }
  }
  goToTasqPage(tasq, view = 'tasq') {
	tasqsListModule.setIsInitialPageLoad(true)
	this.isLoadingSignals = true
	this.failedToPullProductionData = false
    this.$router.push({
      name: 'Tasqs',
      params: {
        id: tasq?.id || '',
      },
      query: { type: 'id', view },
    });
	  tasqsListModule.setIsEditing(false);
    assetsModule.setActiveProducingTasq('');
    tasqsListModule.setActiveTasq(tasq?.id || '');
  }
  @Watch('$route.params.id', { immediate: true })
  async onTasqRouteIdChange(id: string) {
	tasqsListModule.setIsInitialPageLoad(true)
	this.isLoadingSignals = true
	this.failedToPullProductionData = false
    const { wellName } = this.$route.query;
    if (id) {
      this.fetchTasq();
      // this.getPredictionList();
      this.padWellsSkipToBeginning();
    } else {
      tasqsListModule.setActiveTasq('');
    }
    this.multiTasqType = '';
  }
	get getWellHistory() {
		return workflowModule.wellHistory;
  }
  @Watch('showWellEventsLocal')
  onUpdateShowWellEvents(data) {
    tasqProductionDataChartModule.setShowWellEvents(data);
	(this.$refs.myChildRefProd as TasqLightningProductionChart).addHistoryToChart(data)
	// this.$refs.child
	// this.showWellEvents = !showWellEvents
    // this.onWellHistoryUpdated();
    // this.$eventBus.$emit('show-well-events-local', data)
  }
}
